<template>
  <div>
    <b-container fluid>
      <div v-if="selectedFactoryName.includes('外來監控')" class="pageTitle">
        {{ selectedFactoryName }}
      </div>

      <div v-else class="realtime_Multiselect">
        <multiselect
          ref="multiselectRef"
          v-model="selectedFactoryName"
          :options="getFactoryInfoNameList"
          @select="handleSelectFactoryName"
          @open="handleOpenFactoryNameSelection"
          placeholder="案場名稱"
          :max-height="600"
          :preserveSearch="true"
          :allow-empty="false"
          :show-labels="false"
          :clear-on-select="false"
        >
          <template #option="props">
            <span>{{ props.option }}</span>
            <i
              v-b-tooltip="'複製名稱'"
              class="far fa-copy copy-button ml-4"
              @click.stop="copyToClipboard(props.option)"
            />
          </template>
          <span slot="noResult"> 查詢無資料，請重新搜尋 </span>
        </multiselect>

        <div
          v-if="routeQuery.demo === 'true' && hasRoleAdmin"
          class="ml-2 peekDisplayNameBtn"
          v-b-tooltip.right="tooltipDisplaySiteName(routeQuery.demo)"
        >
          <i class="fas fa-search"></i>
        </div>
      </div>

      <b-row>
        <b-col class="category-wrapper">
          <router-link
            :to="{
              path: `/${lang}/siteinfo/siteinfodetail`,
              query: routeQuery,
            }"
            v-if="
              rolePermissionArray.includes('siteinfodetail') &&
              !isForeignFactory
            "
          >
            <div
              class="info-category"
              :class="{ active: path === 'siteinfodetail' }"
            >
              <i class="fa fa-cloud m-5px" />{{ $t("siteInfo.energyInfo") }}
            </div>
          </router-link>

          <router-link
            :to="{ path: `/${lang}/siteinfo/devicestatus`, query: routeQuery }"
            v-if="
              rolePermissionArray.includes('devicestatus') && !isForeignFactory
            "
          >
            <div
              class="info-category"
              :class="{ active: path === 'devicestatus' }"
            >
              <i class="fa fa-check-circle m-5px" />{{
                $t("siteInfo.DeviceStatus")
              }}
            </div>
          </router-link>

          <router-link
            :to="{ path: `/${lang}/siteinfo/devicehistory`, query: routeQuery }"
            v-if="
              rolePermissionArray.includes('devicehistory') && !isForeignFactory
            "
          >
            <div
              class="info-category"
              :class="{ active: path === 'devicehistory' }"
            >
              <i class="fa fa-history m-5px" />{{
                $t("siteInfo.DeviceHistory")
              }}
            </div>
          </router-link>

          <router-link
            :to="{ path: `/${lang}/siteinfo/dataanalysis`, query: routeQuery }"
            v-if="
              rolePermissionArray.includes('dataanalysis') && !isForeignFactory
            "
          >
            <div
              class="info-category"
              :class="{ active: path === 'dataanalysis' }"
            >
              <i class="fa fa-flask m-5px" />{{ $t("siteInfo.DataAnalysis") }}
            </div>
          </router-link>

          <router-link
            :to="{ path: `/${lang}/siteinfo/mpptanalysis`, query: routeQuery }"
            v-if="
              rolePermissionArray.includes('mpptanalysis') &&
              routeQuery.demo === 'false' &&
              !isForeignFactory
            "
          >
            <div
              class="info-category"
              :class="{ active: path === 'mpptanalysis' }"
            >
              <i class="fa fa-bolt m-5px" />MPPT分析
            </div>
          </router-link>

          <router-link
            :to="{ path: `/${lang}/siteinfo/dataexcel`, query: routeQuery }"
            v-if="
              rolePermissionArray.includes('dataexcel') &&
              routeQuery.demo === 'false'
            "
          >
            <div
              class="info-category"
              :class="{ active: path === 'dataexcel' }"
            >
              <i class="fas fa-drafting-compass m-5px" />{{
                $t("siteInfo.DesignInfo")
              }}
            </div>
          </router-link>

          <router-link
            :to="{
              path: `/${lang}/siteinfo/investmentanalysis`,
              query: routeQuery,
            }"
            v-if="
              rolePermissionArray.includes('investmentanalysis') &&
              routeQuery.demo === 'false'
            "
          >
            <div
              class="info-category"
              :class="{ active: path === 'investmentanalysis' }"
            >
              <i class="fas fa-chart-line m-5px" />{{
                $t("siteInfo.InvestmentAnalysis")
              }}
            </div>
          </router-link>

          <router-link
            :to="{ path: `/${lang}/siteinfo/monthlyreport`, query: routeQuery }"
            v-if="
              rolePermissionArray.includes('monthlyreport') &&
              routeQuery.demo === 'false' &&
              !isForeignFactory
            "
          >
            <div
              class="info-category"
              :class="{ active: path === 'monthlyreport' }"
            >
              <i class="fas fa-file m-5px" />{{ $t("siteInfo.report") }}
            </div>
          </router-link>

          <router-link
            :to="{
              path: `/${lang}/siteinfo/otherinformation`,
              query: routeQuery,
            }"
            v-if="
              rolePermissionArray.includes('otherinformation') &&
              routeQuery.demo === 'false' &&
              !isForeignFactory
            "
          >
            <div
              class="info-category"
              :class="{ active: path === 'otherinformation' }"
            >
              <i class="fas fa-th-list m-5px" />{{ $t("siteInfo.otherInfo") }}
            </div>
          </router-link>

          <router-link
            :to="{ path: `/${lang}/siteinfo/deviceinfo`, query: routeQuery }"
            v-if="false"
          >
            <div
              class="info-category"
              :class="{ active: path === 'deviceinfo' }"
            >
              <i class="fas fa-info-circle m-5px" />設備資訊
            </div>
          </router-link>

          <router-link
            :to="{ path: `/${lang}/siteinfo/videostream`, query: routeQuery }"
            v-if="liveEvent"
          >
            <div
              class="info-category"
              :class="{ active: path === 'videostream' }"
            >
              <i class="fas fa-video m-5px" />影像串流
            </div>
          </router-link>

          <router-link
            :to="{
              path: `/${lang}/siteinfo/pvstringfailureanalysis/powerfee`,
              query: routeQuery,
            }"
            v-show="false"
          >
            <div
              class="info-category"
              :class="{ active: path === 'pvstringfailureanalysis' }"
            >
              <i class="fa fa-check-circle m-5px" />{{
                $t("siteInfo.FailureAnalysis")
              }}
            </div>
          </router-link>
        </b-col>
        <b-col class="p-0" align-self="center" v-if="false">
          <div class="marquee" style="margin-bottom: 30px">
            <span class="content">
              {{ $t("siteInfo.LatestInformation") }}{{ marqueeMsg }}
            </span>
          </div>
        </b-col>
      </b-row>
      <router-view />
    </b-container>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import { isArray } from 'lodash'
import i18n from '../lang/lang'
import Multiselect from 'vue-multiselect'
export default {
  name: 'SiteInfo',
  components: {
    Multiselect
  },
  data () {
    return {
      acmeAdmin: false,
      liveEvent: false,
      breadcrumbItems: [
        {
          text: '首頁',
          to: {
            name: 'realtime'
          }
        },
        {
          text: '發電資訊',
          active: true
        }
      ],
      infoCateActive: 1,
      routeQuery: {},
      marqueeMsg: '',
      marInterval: '',
      marqueeContent: '',
      selectedFactoryName: ''
    }
  },
  computed: {
    ...mapState([
      'factoryInfo',
      'factoryData',
      'hasRoleAdmin',
      'userDetail',
      'showMarquee',
      'roleComponentMenu',
      'rolePermissionArray'
    ]),
    ...mapGetters(['getFactoryInfoNameList']),

    lang () {
      return this.$route.params.lang
    },
    path () {
      return this.$route.name
    },
    isForeignFactory () {
      return this.routeQuery.foreign === 'true'
    }
  },
  watch: {
    showMarquee: function () {
      this.marquee()
    },
    '$route.query' (to, from) {
      // fix toSiteInfo 網址有變化 但頁面沒有載入問題
      // fix 若使用 this.$router.go(0) 返回上一頁，網址沒有變化
      if (to.siteName !== from.siteName) {
        window.location.reload()
      }
    }
  },
  methods: {
    ...mapMutations(['UPDATE_DATA_ANALYSIS_STATE', 'ADD_QUERY_SITE_NAME']),
    ...mapActions(['asyncSpecificLastDoc']),
    async copyToClipboard (text) {
      try {
        await navigator.clipboard.writeText(text)
        this.$swal('複製成功')
      } catch (err) {
        console.error('Failed to copy: ', err)
      }
    },
    handleSelectFactoryName (selectedFactoryName) {
      this.goToSingleSiteInfo(selectedFactoryName)
    },
    handleOpenFactoryNameSelection () {
      // selection open，捲軸移至已選選項
      this.$nextTick(() => {
        const multiselect = this.$refs.multiselectRef.$el
        const selected = multiselect.querySelector(
          '.multiselect__option--selected'
        )
        const dropdown = multiselect.querySelector(
          '.multiselect__content-wrapper'
        )
        if (selected && dropdown) {
          dropdown.scrollTop = selected.offsetTop
          // dropdown.scrollTop = selected.offsetTop - dropdown.offsetTop
        }
      })
    },
    marquee () {
      let time = DateTime.local().toLocaleString({
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        locale: 'zh-tw'
      })
      this.marqueeMsg = ' ' + time
    },
    tooltipDisplaySiteName (isDemo) {
      if (isDemo === 'true') {
        const demoIndex = this.routeQuery.siteName.split(' ')[1] - 1
        return this.factoryInfo[demoIndex].factory
      }
    },
    async goToSingleSiteInfo (selectedFactoryName) {
      // filter Info 有找到 selectedFactoryName 且 不在展示的 Data 內
      const querySiteDataArr = this.factoryInfo
        .filter((info) => info.displaySiteName.includes(selectedFactoryName))
        .filter(
          (info) =>
            !this.factoryData
              .map((data) => data?.displaySiteName)
              .includes(info.displaySiteName)
        )

      if (querySiteDataArr?.length) {
        await this.asyncSpecificLastDoc(querySiteDataArr)
      }

      const singleData = this.factoryData.filter(
        (x) => x.displaySiteName === selectedFactoryName
      )
      const { factoryId, isDemoSite } = singleData[0]

      const invLen = singleData[0].Eff?.length ?? 0
      const pvLen = singleData[0].pv_a
        ? Math.max(...singleData[0].pv_a.map((x) => x.length))
        : singleData[0].mppt_a
          ? Math.max(...singleData[0].mppt_a.map((x) => x.length))
          : 0
      const anemometerLen = isArray(singleData[0].Anemometer)
        ? singleData[0].Anemometer.length
        : singleData[0].Anemometer || singleData[0].Anemometer === 0
          ? 1
          : 0

      const routerQueryParams = {
        siteName: singleData[0].displaySiteName,
        invLen,
        pvLen,
        anemometerLen,
        lang: i18n.locale,
        fid: factoryId,
        demo: isDemoSite ? 'true' : 'false'
      }

      this.toSiteInfo(routerQueryParams)
    },
    toSiteInfo (routerQueryParams) {
      if (this.roleComponentMenu.length) {
        const to = this.roleComponentMenu[0]
        this.$router.push({
          path: `/${i18n.locale}/siteinfo/${to}`,
          query: routerQueryParams
        })
      } else {
        this.$swal('權限不足')
        this.$router.push({
          path: `/${i18n.locale}/realtime`
        })
      }
    }
  },
  async created () {
    this.routeQuery = this.$route.query
    this.ADD_QUERY_SITE_NAME(this.$route.query.siteName)
    this.UPDATE_DATA_ANALYSIS_STATE()
    this.selectedFactoryName = this.routeQuery.siteName
  }
}
</script>

<style
  src="../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"
></style>

<style lang="scss" scoped>
$activeColor: #7db9e8;

.copy-button {
  opacity: 0;
}

:deep(.realtime_Multiselect) {
  display: inline-flex;
  padding: 4px;
  margin: 5px 0px;
  margin-left: -13px;

  input[type="text"] {
    font-family: "標楷體";
    color: #5f6368;
    letter-spacing: 0.2rem;
  }

  // cover vue-multiselect style
  .multiselect {
    &:hover {
      cursor: pointer;

      .multiselect__select:before {
        color: $activeColor;
        border-color: $activeColor transparent transparent;
      }
    }
  }

  .multiselect__tags {
    padding: 9px 40px 0px 8px;
    border: 0;
    // min-width: 193px;
  }

  .multiselect__option {
    font-size: 15px;
  }

  .multiselect__option--highlight {
    background: $activeColor;
    .copy-button {
      opacity: 1;
    }
  }

  .multiselect__option--selected {
    background: $activeColor;
    color: white;
    font-weight: 700;
  }

  .multiselect__option--selected.multiselect__option--highlight {
    background: #b3b6b9;
    color: #fff;
  }

  .multiselect__input::placeholder {
    color: #9fa2a4;
    font-size: calc(15rem / 16);
    font-family: system, -apple-system, BlinkMacSystemFont, "PingFang TC", Lato,
      "Segoe UI", "Microsoft JhengHei", Montserrat, Roboto, "Helvetica Neue",
      Arial, sans-serif;
    letter-spacing: 1px;
  }

  .multiselect__single {
    min-width: 193px;
    font-family: "標楷體";
    color: #5f6368;
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 0.2rem;
    text-shadow: 0px 15px 5px rgba(0, 0, 0, 0.1),
      10px 20px 5px rgba(0, 0, 0, 0.05), -10px 20px 5px rgba(0, 0, 0, 0.05);
  }

  // options 不要捲軸
  // .multiselect__content-wrapper {
  //   width: auto;
  // }
}
</style>

<style lang="scss" scoped>
@import "../assets/scss/siteInfo.scss";
</style>
